import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import { updateOrderStatusToClosed } from '@app/crud/apv/order.crud';
import { Order } from '@app/crud/apv/order.type';

import { useAppSelector, useModal } from '@app/hooks';
import { manageApvInStoreListing } from '@app/pages/customers/Helpers/ExternalData';
import { actions } from '@app/store/modal/modal.store';

import ActionButton from '@app/partials/content/ActionButton';
import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

import { getActions } from '../../../Helpers/Actions';

interface APVInStoreCloseProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

const APVInStoreClose = ({ showModal, setShowModal }: APVInStoreCloseProps) => {
    const Intl = useIntl();
    const dispatch = useDispatch();
    const { externalData } = useAppSelector((state) => state.customer.customer);
    const [_show, _toggle, params] = useModal(false, 'apv_in_store_close') as [
        boolean,
        (show: boolean) => void,
        { order: Order },
    ];
    const [isLoading, setIsLoading] = useState(false);

    const order = params?.order;
    const closingActions = getActions(['apv_in_store_won', 'apv_in_store_lose']);

    const handleClick = async (action: string = undefined) => {
        switch (action) {
            case 'APV_IN_STORE_WON':
                dispatch(actions.modalChange('apv_in_store_close_win', { order }));
                break;
            case 'APV_IN_STORE_LOSE':
                try {
                    setIsLoading(true);
                    await updateOrderStatusToClosed(order.id.toString());
                    Swal.fire({
                        title: `<i class="las la-check-circle fa-2x text-primary"></i> <br /> ${Intl.formatMessage({
                            id: 'APV.IN_STORE.QUOTATIONS.MODAL.REJECTED.CONFIRM_TITLE',
                        })}`,
                        text: Intl.formatMessage(
                            { id: 'APV.IN_STORE.QUOTATIONS.MODAL.REJECTED.CONFIRM_TXT' },
                            { order: order?.reference },
                        ),
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-secondary',
                        },
                        buttonsStyling: false,
                        confirmButtonText: Intl.formatMessage({ id: 'CONFIRMATION' }),
                        preConfirm: () => {
                            if (order.automanagerId) {
                                manageApvInStoreListing(externalData, dispatch, false);
                            }
                            setShowModal(false);
                        },
                    });
                } catch (error) {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                } finally {
                    setIsLoading(false);
                }

                break;
            default:
                break;
        }
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-hand-point-right" />}
            title={<FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.CLOSE.TITLE" />}
            body={
                <div className="d-flex py-6 justify-content-lg-center justify-content-between">
                    {isLoading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                    {closingActions.map(([action, properties]) => (
                        <ActionButton
                            {...properties}
                            key={action}
                            onClick={() => handleClick(action)}
                            className="mr-lg-6"
                        />
                    ))}
                </div>
            }
            footer={
                <div className="d-flex py-6 justify-content-lg-center justify-content-between">
                    <Button variant="outline-secondary" className="mr-5" onClick={() => setShowModal(false)}>
                        <FormattedMessage id="TRANSLATOR.CLOSE" />
                    </Button>
                </div>
            }
        />
    );
};

export default APVInStoreClose;
