import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import { getSlotsByDealership } from '@app/crud/apv/dealership.crud';
import { confirmOrderInStore } from '@app/crud/apv/order.crud';
import { Order } from '@app/crud/apv/order.type';

import { useAppDispatch, useAppSelector, useModal, useThunkDispatch } from '@app/hooks';
import { manageApvInStoreListing } from '@app/pages/customers/Helpers/ExternalData';
import { actions } from '@app/store/modal/modal.store';

import Days from '@app/partials/content/Day/Days';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import Months from '@app/partials/content/Month/Months';
import Slots from '@app/partials/content/Slot/Slots';
import toast from '@app/partials/content/Toast';

interface Props {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

export default function APVInStoreQuotationAccepted({ showModal, setShowModal }: Props) {
    const Intl = useIntl();
    const dispatch = useAppDispatch();
    const thunkDispatch = useThunkDispatch();
    const { externalData } = useAppSelector((state) => state.customer.customer);
    const [_show, _toggle, params] = useModal(false, 'apv_in_store_close_win') as [
        boolean,
        (show: boolean) => void,
        { order: Order },
    ];

    const [loading, setLoading] = useState(false);

    const order = params?.order;
    const dateStart = dayjs(order?.appointment?.dateStart).toDate();
    const [currentSlot, setCurrentSlot] = useState<string | undefined>();
    const [currentDay, setCurrentDay] = useState<string | undefined>();
    const [currentMonth, setCurrentMonth] = useState<string | undefined>();
    const [date, setDate] = useState<string | undefined>();

    const initialize = () => {
        setCurrentSlot(dayjs(dateStart).format('HH:mm'));
        setCurrentDay(dayjs(dateStart).format('YYYY-MM-DD'));
        setCurrentMonth(dayjs(dateStart).format('YYYY-MM'));
        setDate(dayjs(dateStart).format('YYYY-MM-DD'));
    };

    useEffect(() => {
        initialize();
    }, []);

    const [slots, setSlots] = useState<{ hour: string; label: string; booked: boolean }[]>([]);
    const [days, setDays] = useState<Record<string, { day: string; label: string; open: boolean }>>({});
    const [months, setMonths] = useState<Record<string, string>>({});

    const loadModalContent = useCallback(() => {
        setLoading(true);

        if (order?.dealership?.id && date) {
            getSlotsByDealership(String(order.dealership.id), date || '', order?.mecaplanning?.duration)
                .then((response) => {
                    setMonths(response.result.months);
                    setDays(response.result.days);
                    setSlots(response.result.slots);
                    setLoading(false);
                })
                .catch(() => {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CONTACT.ERROR' }),
                    });
                });
        }
    }, [date, order]);

    useEffect(() => {
        loadModalContent();
    }, [loadModalContent]);

    const handleCurrentMonth = (month: string) => {
        setCurrentMonth(month);
        setCurrentDay('');
        setCurrentSlot('');
        setDate(month);
    };

    const handleCurrentDay = (day: string) => {
        setCurrentDay(day);
        setCurrentSlot('');
        setDate(day);
    };

    const handleClose = () => {
        setShowModal(false);
        initialize();
    };

    const onConfirmWon = async () => {
        setLoading(true);

        try {
            const confirmParams = {
                appointment: { dateStart: `${dayjs(date).format('YYYY-MM-DD')} ${currentSlot || ''}:00` },
            };
            await confirmOrderInStore(String(order.id), confirmParams);

            manageApvInStoreListing(externalData, thunkDispatch);

            dispatch(
                actions.modalChange('apv_in_store_close_win_confirm', {
                    order,
                    date: dayjs(`${date} ${currentSlot}`, 'YYYY-MM-DD HH:mm'),
                }),
            );
        } catch (error) {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            onEnter={loadModalContent}
            hideModal={handleClose}
            icon={<i className="las la-wrench la-2x text-primary" />}
            title={Intl.formatMessage({ id: 'APV.IN_STORE.QUOTATIONS.MODAL.ACCEPTED.TITLE' })}
            body={
                <div className="px-5">
                    <div className="mt-4 mb-3">
                        <Months months={months} currentMonth={currentMonth || ''} onSelect={handleCurrentMonth} />
                    </div>
                    <hr />
                    <div className="mt-4 mb-3">
                        <Days days={days} currentDay={currentDay || ''} onSelect={handleCurrentDay} />
                    </div>
                    <hr />
                    <div className="mt-4 mb-3">
                        <Slots
                            slots={slots}
                            currentSlot={currentSlot || ''}
                            onSelect={setCurrentSlot}
                            unclickable={currentDay === ''}
                        />
                    </div>
                    {!order?.isMecaplanning && (
                        <div className="mt-8 d-flex font-weight-bold">
                            <i className="las la-3x la-exclamation-circle text-light mr-3 la-bullseye" />
                            <FormattedHTMLMessage id="APV.IN_STORE.QUOTATIONS.MODAL.WARNING.MECA_TXT" />
                        </div>
                    )}
                </div>
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button
                        variant="outline-secondary"
                        className="mr-3"
                        style={{ width: '100px' }}
                        onClick={handleClose}
                    >
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button
                        variant="primary"
                        className="position-relative"
                        style={{ width: '100px' }}
                        onClick={onConfirmWon}
                    >
                        {Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                    </Button>
                </div>
            }
        />
    );
}
