import type H from 'history';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { ApiError } from '@app/helpers/AxiosHelper';
import { routeTo } from '@app/helpers/RoutesHelper';

import { getAllFastNotes } from '@app/crud/administration/fast-notes.crud';
import { getOrders } from '@app/crud/apv/order.crud';
import {
    addCustomer,
    getActionData,
    getAllParams,
    getCustomer,
    getCustomers,
    GetCustomersParams,
    getCustomersPortfolio,
    getCustomerWithActiveAction,
    getEventsCustomerLife,
    getLeadReason,
    updateCustomerById,
} from '@app/crud/customers/customer.crud';
import { Customer, ExternalData } from '@app/crud/customers/customer.type';
import { getContractsByCustomerId } from '@app/crud/securyweb/securyweb.crud';

import ROUTES from '@app/router/Routes';
import store from '@app/store/store';

import toast from '@app/partials/content/Toast';

import { actions as actionsModal } from '../modal/modal.store';
import { RootState } from '../rootDuck';

import { actions } from './customers.store';

export function fetchParams(): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        if (!store.getState().customer.params) {
            getAllParams().then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.fetchedParams(response.result));
                }
            });
        }
    };
}

export function fetchLeadReasons(): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        if (!store.getState().customer.leadReasons) {
            getLeadReason().then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.fetchedLeadReasons(response.result));
                }
            });
        }
    };
}

export function fetchTags(): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        if (!store.getState().customer.tags) {
            getAllFastNotes().then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.fetchedTags(response.result));
                }
            });
        }
    };
}

export function refreshLeads(id: string, action: any): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        getCustomer(id).then((customer) => {
            if (!customer?.result?.error && customer?.result && customer?.result?.leads) {
                dispatch(actions.leadUpdated(customer.result.contact, customer.result.leads, action));
            }
        });
    };
}

export function resetCustomers(): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        dispatch(actions.resetCustomers());
    };
}

export function resetCustomer(): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        dispatch(actions.resetCustomer());
    };
}

export function fetchCustomers(params: GetCustomersParams): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        dispatch(actions.fetchCustomers());
        getCustomers(params)
            .then((response) => {
                dispatch(actions.fetchedCustomers(response));
            })
            .catch((err) => {
                dispatch(actions.fetchCustomersFailed(err));
            });
    };
}

export function fetchCustomersWithAction(params: GetCustomersParams): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        dispatch(actions.fetchCustomers());
        getCustomerWithActiveAction(params)
            .then((response) => {
                dispatch(actions.fetchedCustomers(response));
            })
            .catch((err) => {
                dispatch(actions.fetchCustomersFailed(err));
            });
    };
}

export function fetchCustomersPortfolio(params: GetCustomersParams): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        dispatch(actions.fetchCustomers());
        getCustomersPortfolio(params)
            .then((response) => {
                dispatch(actions.fetchedCustomers(response));
            })
            .catch((err) => {
                dispatch(actions.fetchCustomersFailed(err));
            });
    };
}

export function fetchActionData(id: string): ThunkAction<void, {}, {}, AnyAction> {
    return (dispatch) => {
        dispatch(actions.fetchActionData('business'));

        if (id) {
            getActionData(id)
                .then((data) => {
                    if (data?.result?.error && id) {
                        dispatch(actions.fetchActionDataFailed(data.result));
                    } else {
                        dispatch(
                            actions.fetchedActionData({
                                result: data?.result || [],
                                type: 'business',
                            }),
                        );
                    }
                })
                .catch((error: ApiError) => {
                    dispatch(
                        actions.fetchActionDataFailed({
                            type: 'business',
                            error,
                        }),
                    );
                });
        } else {
            dispatch(
                actions.fetchedActionData({
                    result: null,
                    type: 'business',
                }),
            );
        }
    };
}

export function fetchCustomer({
    id,
    history,
    showAction = true,
}: {
    id: string;
    history?: H.History;
    showAction?: boolean;
}): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        dispatch(actions.fetchCustomer());
        dispatch(fetchParams());
        dispatch(fetchLeadReasons());
        dispatch(fetchTags());

        getCustomer(id)
            .then((customer) => {
                if (customer?.result?.error && id) {
                    toast({
                        onShow: history.push(routeTo(ROUTES.CUSTOMERS.PATH)),
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                    dispatch(actions.fetchCustomerFailed(customer.result));
                } else {
                    dispatch(
                        actions.fetchedCustomer({
                            customer: {
                                ...(customer?.result?.contact || []),
                                leads: customer?.result?.leads || [],
                                differentialsVCU: customer?.result?.differentialsVCU || [],
                            },
                            externalData: customer?.result?.externalData,
                        }),
                    );
                    dispatch(fetchActionData(customer?.result?.contact?.vcuId));
                }
                if (!customer?.result?.leads.length && showAction) {
                    dispatch(actionsModal.modalChange('first_action'));
                }
            })
            .catch(() => {
                toast({
                    onShow: history.push(routeTo(ROUTES.CUSTOMERS.PATH)),
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
                dispatch(actions.fetchCustomerFailed({}));
            });
    };
}

export function fetchContracts(id: string): ThunkAction<void, RootState, unknown, AnyAction> {
    return (dispatch) => {
        dispatch(actions.fetchContracts());
        getContractsByCustomerId(id)
            .then((response) => {
                dispatch(actions.fetchedContracts(response));
            })
            .catch((err) => {
                dispatch(actions.fetchContractsFailed(err));
            });
    };
}

export function fetchApvOrders(
    externalData: ExternalData[],
    triggerLoading = true,
): ThunkAction<void, RootState, unknown, AnyAction> {
    const references = externalData.map((externService) => externService?.reference);
    return (dispatch) => {
        if (triggerLoading) dispatch(actions.fetchAPVOrders());
        getOrders({ references: references.join(',') })
            .then((response) => {
                dispatch(actions.fetchedAPVOrders(response.result));
            })
            .catch((err) => {
                dispatch(actions.fetchAPVOrdersFailed(err));
            });
    };
}

export function fetchCustomerLife(id: string): ThunkAction<void, {}, {}, AnyAction> {
    return (dispatch) => {
        dispatch(actions.fetchActionData('activity'));

        if (id) {
            getEventsCustomerLife(id)
                .then((data) => {
                    if (data?.result?.error && id) {
                        dispatch(actions.fetchActionDataFailed(data.result));
                    } else {
                        dispatch(
                            actions.fetchedActionData({
                                result: data?.result || [],
                                type: 'activity',
                            }),
                        );
                    }
                })
                .catch((error: ApiError) => {
                    dispatch(
                        actions.fetchActionDataFailed({
                            type: 'activity',
                            error,
                        }),
                    );
                });
        } else {
            dispatch(
                actions.fetchedActionData({
                    result: null,
                    type: 'activity',
                }),
            );
        }
    };
}

interface CreateCustomerParams {
    data: Customer;
    history?: H.History;
}

export function createCustomer(params: CreateCustomerParams): ThunkAction<void, {}, {}, AnyAction> {
    return (dispatch) => {
        dispatch(actions.addCustomer());

        addCustomer(params.data)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.addedCustomer(response.result));
                    params.history.push(
                        routeTo(ROUTES.CUSTOMER.PATH, {
                            id: response.result.id,
                        }),
                    );
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'CUSTOMERS.ADD.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.addCustomerFailed());
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'CUSTOMERS.ADD.FAILED' }),
                    });
                }
            })
            .catch(() => {
                dispatch(actions.addCustomerFailed());
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMERS.ADD.FAILED' }),
                });
            });
    };
}

export function updateCustomer(
    id: string,
    data: Customer,
    history?: H.History,
    closeModal = true,
    showAction = true,
): ThunkAction<void, {}, {}, AnyAction> {
    return (dispatch) => {
        dispatch(actions.updateCustomer());

        updateCustomerById(id, data)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.updatedCustomer(response.result));
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'CUSTOMER.UPDATE.SUCCESS' }),
                    });
                    closeModal && dispatch(actionsModal.closeModals());
                    dispatch(fetchCustomer({ id, history, showAction }));
                } else {
                    dispatch(actions.updateCustomerFailed());
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'CUSTOMER.UPDATE.FAILED' }),
                    });
                }
            })
            .catch(() => {
                dispatch(actions.updateCustomerFailed());
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMER.UPDATE.FAILED' }),
                });
            });
    };
}
