import { Package } from '@app/pages/apv/orders/Order';
import { Department } from '@app/pages/customers/Modals/APVInStore/APVInStore.type';

export type FetchOrderParams = {
    orderType?: string;
    automanagerId?: string;
    orderStatus?: string[];
    orderBy?: string;
    research?: string;
    start?: number;
    items?: number;
    customer?: string;
    reference?: string;
    vehicleName?: string;
    dealersiphId?: number;
    statusId?: number;
    dateStartAppointment?: string;
    dateEndAppointment?: string;
    dateStartOrder?: string;
    dateEndOrder?: string;
    statusApvInStoreByGroup?: string;
    references?: string;
};

export interface ApiResponse<T> {
    result: T & { error?: string; error_description?: string; error_code?: string };
}

export interface OrdersApiResponse<T> {
    result: T;
    count: number;
}

export interface Dealership {
    active: boolean;
    compta: string;
    created: string;
    dealershipSource: {
        active: boolean;
        address: string;
        city: string;
        codePvo: string;
        created: string;
        id: string;
        lat: string;
        latSearch: string;
        legal_name: string;
        long: string;
        longSearch: string;
        make: string;
        type: string;
        updated: string;
        usual_name: string;
        xInitiale: string;
        zipcode: string;
    };
    department: Department;
    id: number;
    minDays: number;
    nbSlots: number;
    phone: string;
    phoneLandLine: string;
    sendToCall: boolean;
    sourceActive: boolean;
    updated: string;
}

export interface Order {
    id: number;
    reference: string;
    autouserId: number;
    autouserFirstName: string;
    autouserLastName: string;
    comment: null;
    totalOrderAmount: number;
    totalVatAmount: number;
    totalOrderAmountWithoutVat: number;
    dealershipName: string | null;
    autosphereId: number | null;
    automanagerId: number | null;
    firstname: string | null;
    lastname: string | null;
    email: string | null;
    zipcode: string | null;
    city: string | null;
    civility: string | null;
    address: string | null;
    phone: string | null;
    mobile: string | null;
    make: string;
    model: string;
    version: string;
    dateFirstRegistration: string;
    registration: string;
    vin: string;
    mileage: number;
    pathImg: string;
    makeCode: number;
    modelCode: number;
    nationalCode: number;
    makeSegment: string;
    bodyTypeSegment: string;
    vehicleTypeCode: number;
    created: string;
    updated: string;
    dealership: Dealership | null;
    orderStatus: {
        id: number;
        name: string;
        slug: string;
        created: string;
        updated: string;
    };
    orderStatusApvInStore?: {
        id: number;
        name: string;
        subName: string;
        slug: 'draft' | 'waiting_for_customer' | 'closed_win' | 'closed_lose';
        groupName: string;
        groupSlug: 'draft' | 'waiting_for_customer' | 'closed';
        created: string;
        updated: string;
    };
    orderStatusReason: null;
    orderType: {
        id: number;
        name: string;
        slug: string;
        created: string;
        updated: string;
    };
    orderPackages: Package[];
    departmentNumber: string;
    appointment: {
        id: number;
        name: string;
        dateStart: string;
        dateEnd: string;
        comment: string;
        created: string;
        updated: string;
        orderAppointmentStatusApvInStore: {
            id: number;
            name: string;
            slug: string;
            created: string;
            updated: string;
        };
    } | null;
    events: OrderEvent[];
    orderPayment: null;
    mecaplanning: { duration: number; rdvId: string; status: string; reception: string } | null;
    isMecaplanning: boolean;
    loanVehicleAsked: boolean;
    needPayment: boolean;
    nextTechnicalInspectDate: string;
}

export interface UpdateOrderParams {
    action: string;
    appointment?: object | null;
    orderStatusReasonId?: number;
}

export interface OrderEvent {
    id: number;
    name: string;
    created: string;
    updated: string;
    eventType: {
        id: 1;
        name: string;
        slug: string;
        icon: string;
        created: string;
        updated: string;
    };
    eventSource: {
        id: number;
        name: string;
        slug: string;
        color: string;
        created: string;
        updated: string;
    };
}
export interface CompleteCounter {
    id: number;
    name: string;
    slug: string;
    created: string;
    updated: string;
}

export interface OrderInStore {
    id: number;
    reference: string;
    comment: string;
    totalOrderAmount: number;
    totalVatAmount: number;
    totalOrderAmountWithoutVat: number;
    dealershipName: string;
    autosphereId: string;
    firstname: string;
    lastname: string;
    email: string;
    zipcode: string;
    city: string;
    address: string;
    phone: string;
    mobile: string;
    make: string;
    model: string;
    version: string;
    dateFirstRegistration: string;
    registration: string;
    mileage: number;
    pathImg: null;
    makeCode: number;
    modelCode: number;
    nationalCode: number;
    makeSegment: string;
    bodyTypeSegment: string;
    vehicleTypeCode: number;
    created: string;
    updated: string;
    dealership: Dealership;
    orderStatus: string;
    orderStatusReason: string;
    orderType: CompleteCounter;
    orderPackages: Package[];
    departmentNumber: null;
    appointment?: {
        id: number;
        name: string;
        dateStart: string;
        dateEnd: string;
        created: string;
        updated: string;
        orderAppointmentStatusApvInStore: CompleteCounter;
    };
    events: [];
    orderPayment: null;
    mecaplanning: {
        duration: number;
    };
    isMecaplanning: boolean;
    loanVehicleAsked: boolean;
    needPayment: boolean;
    orderStatusApvInStore: CompleteCounter & {
        subName: string;
        groupName: string;
        groupSlug: string;
    };
    autouserFirstName: string;
    autouserLastName: string;
    filename?: string;
}

export interface UpdateAppointmentOrderParams {
    dateStart: string;
}

export type ConfirmQuoteAndShowResponse = ApiResponse<OrderInStore> & { pdfContent: string };
export enum OrderType {
    ORDER_DESK = 'order-desk',
    COMMANDE = 'commande',
}

export enum Variant {
    SUCCESS = 'success',
    DARK = 'dark',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    DANGER = 'danger',
    WARNING = 'warning',
}
