// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { convertBase64ToBlob } from '@app/helpers/ObjectHelper';
import { getUserDealershipById } from '@app/helpers/UserHelper';

import {
    confirmQuoteAndSend,
    confirmQuoteAndShow,
    createOrderInStore,
    updateOrderInStore,
} from '@app/crud/apv/order.crud';
import { OrderInStore } from '@app/crud/apv/order.type';

import { useAppSelector, useThunkDispatch } from '@app/hooks';
import { manageApvInStoreListing } from '@app/pages/customers/Helpers/ExternalData';

import ButtonWithLoader from '@app/partials/content/ButtonWithLoader';
import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import ModalStepper, { ModalStepperType } from '@app/partials/content/modals/ModalStepper';
import toast from '@app/partials/content/Toast';

import { APVInStoreForm } from './APVInStore.type';
import { APVInStoreProvider } from './APVInStoreContext';
import { APVInStoreDealership, APVInStorePackages, APVInStoreSummary } from './partials';
import APVInStoreCustomer from './partials/APVInStoreCustomer';

interface APVInStoreProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

const APVInStore = ({ showModal, setShowModal }: APVInStoreProps) => {
    const Intl = useIntl();
    const dispatch = useThunkDispatch();
    const { data: customer, externalData } = useAppSelector((state) => state.customer.customer);
    const methods = useForm();
    const { setValue, getValues, handleSubmit, control } = methods;
    const [isLoading, setIsLoading] = useState(false);
    const [order, setOrder] = useState<OrderInStore>();
    const orderId = getValues('id');
    const [isDownloadingPdf, setDownloadingPdf] = useState(false);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [isCompatibleApvInStore, setIsCompatibleApvInStore] = useState(false);

    const [stepperState, setStepperState] = useState<ModalStepperType>({
        currentStep: 0,
        steps: [
            {
                name: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.APVINSTORE.STEP.DEALERSHIP.TITLE' }),
                status: 'pending',
                content: <APVInStoreDealership setIsCompatibleApvInStore={setIsCompatibleApvInStore} />,
            },
            {
                name: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.APVINSTORE.STEP.PACKAGES.TITLE' }),
                status: 'pending',
                content: <APVInStorePackages />,
            },
            {
                name: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.APVINSTORE.STEP.CUSTOMER.TITLE' }),
                status: 'pending',
                content: <APVInStoreCustomer />,
            },
            {
                name: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.APVINSTORE.STEP.SUMMARY.TITLE' }),
                status: 'pending',
                content: <APVInStoreSummary />,
            },
        ],
    });

    const handleDownloadPdf = async () => {
        setDownloadingPdf(true);
        try {
            const response = await confirmQuoteAndShow(orderId);
            if (!response.pdfContent) {
                throw new Error('no pdf content');
            }
            const blob = convertBase64ToBlob(response.pdfContent);
            saveAs(blob, response.result.filename);

            manageApvInStoreListing(externalData, dispatch);
            setShowModal(false);
        } catch {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({
                    id: 'CUSTOMERS.MODAL.APVINSTORE.SUMMARY.DOWNLOADING_PDF_ERROR',
                }),
            });
        } finally {
            setDownloadingPdf(false);
        }
    };

    const handleSendEmail = async () => {
        setIsSendingEmail(true);
        try {
            await confirmQuoteAndSend(orderId);
            toast({
                variant: 'success',
                message: Intl.formatMessage({
                    id: 'CUSTOMERS.MODAL.APVINSTORE.SUMMARY.SEND_EMAIL_SUCCESS',
                }),
            });

            manageApvInStoreListing(externalData, dispatch);
            setShowModal(false);
        } catch {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({
                    id: 'CUSTOMERS.MODAL.APVINSTORE.SUMMARY.SEND_EMAIL_ERROR',
                }),
            });
        } finally {
            setIsSendingEmail(false);
        }
    };

    useEffect(() => {
        if (showModal) {
            setStepperState((prevState) => ({ ...prevState, currentStep: 0 }));
        }
    }, [showModal]);

    const handleChangeStep = (step: number) => {
        setStepperState((prevState) => {
            let newStep = prevState.currentStep;
            newStep = step;
            return { ...prevState, currentStep: newStep };
        });
    };

    const handleVehicleForm = async (formData: APVInStoreForm) => {
        try {
            setIsLoading(true);
            const userDealership = getUserDealershipById(formData.dealership);

            const quotationData = {
                dealershipId: userDealership.id,
                automanagerId: customer.id,
                vehicle: {
                    make: formData.brand.name,
                    model: formData.model.name,
                    version: formData.version.Version,
                    registration: formData.immat,
                    dateFirstRegistration: dayjs(formData.dateFirstRegistration).format('DD/MM/YYYY'),
                    mileage: formData.kms,
                    makeCode: formData.version.MakeCode,
                    modelCode: formData.version.ModelCode,
                    makeSegment: formData.version.VehicleMakeSegmentId,
                    vehicleTypeCode: formData.version.VehicleType.VehicleTypeCode,
                    nationalCode: formData.version.NationalVehicleCode,
                },
            };

            let response;
            if (getValues('id')) {
                response = await updateOrderInStore(getValues('id'), quotationData);
            } else {
                response = await createOrderInStore(quotationData);
            }

            if (response?.result?.id) {
                setOrder(response.result);
                setValue('id', response.result.id);
                setValue('dealershipDepartmentId', response.result.dealership.department.id);
                setValue('vehicleBodyTypeSegmentId', response.result.bodyTypeSegment);
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'APV.IN_STORE.QUOTATIONS.DRAFT.SAVED_SUCCESS' }),
                });
            } else {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleCustomerForm = async (formData: APVInStoreForm) => {
        try {
            setIsLoading(true);
            const customerData = {
                customer: {
                    civility: formData.civility,
                    firstname: formData.firstname,
                    lastname: formData.lastname,
                    email: formData.email,
                    zipcode: formData.zipcode,
                    city: formData.city,
                    address: formData.address,
                    mobile: formData.mobile,
                },
            };

            let response;
            if (getValues('id')) {
                response = await updateOrderInStore(getValues('id'), customerData);
            } else {
                response = await createOrderInStore(customerData);
                if (response?.result?.id) {
                    setValue('id', response.result.id);
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'APV.IN_STORE.QUOTATIONS.DRAFT.SAVED_SUCCESS' }),
                    });
                } else {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                }
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onNextSubmit = async (data: APVInStoreForm) => {
        try {
            if (stepperState.currentStep === 0) {
                await handleVehicleForm(data);
            }
            if (stepperState.currentStep === 2) {
                await handleCustomerForm(data);
            }
            handleChangeStep(stepperState.currentStep + 1);
        } catch (error) {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    };
    const onPrevSubmit = () => {
        handleChangeStep(stepperState.currentStep - 1);
    };
    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    return (
        <FormProvider {...methods}>
            <ModalDefault
                show={showModal}
                hideModal={() => {
                    if (getValues('id')) {
                        manageApvInStoreListing(externalData, dispatch);
                    }
                    setShowModal(false);
                }}
                size="lg"
                icon={<i className="la la-2x text-primary la-wrench" />}
                title={<FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.TITLE" />}
                body={
                    <APVInStoreProvider value={{ stepperState, order, setOrder }}>
                        {process.env.NODE_ENV === 'development' && <DevTool control={methods.control} />}
                        <Controller
                            control={control}
                            name="id"
                            render={({ value }) => <input type="hidden" value={value} />}
                        />
                        <Controller
                            control={control}
                            name="dealershipDepartmentId"
                            render={({ value }) => <input type="hidden" value={value} />}
                        />
                        <Controller
                            control={control}
                            name="vehicleBodyTypeSegmentId"
                            render={({ value }) => <input type="hidden" value={value} />}
                        />

                        {isLoading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}

                        <ModalStepper state={stepperState} navigate={false} setStep={handleChangeStep} />
                        {stepperState.steps.map((step, index) => (
                            <div
                                key={`step-${step.name}`}
                                className={classNames('', {
                                    'd-none': stepperState.currentStep !== index,
                                    'apvinstore-handle-modal': stepperState.currentStep === index,
                                })}
                            >
                                {step.content}
                            </div>
                        ))}
                        {stepperState.currentStep !== 3 && (
                            <small>
                                <FormattedMessage id="TRANSLATOR.REQUIRED_FIELDS" />
                            </small>
                        )}
                    </APVInStoreProvider>
                }
                footer={
                    <div
                        className={classNames({
                            'd-flex justify-content-end': stepperState.currentStep === 0,
                            'd-flex justify-content-between': stepperState.currentStep > 0,
                        })}
                    >
                        {stepperState.currentStep > 0 && (
                            <Button variant="outline-primary" onClick={onPrevSubmit}>
                                <FormattedMessage id="TRANSLATOR.PREVIOUS" />
                            </Button>
                        )}
                        {stepperState.currentStep < stepperState.steps.length - 1 ? (
                            <Button
                                variant="outline-primary"
                                onClick={handleSubmit(onNextSubmit, onError)}
                                disabled={stepperState.currentStep === 0 && !isCompatibleApvInStore}
                            >
                                <FormattedMessage id="TRANSLATOR.NEXT" />
                            </Button>
                        ) : (
                            <div className="d-flex gap-3">
                                <ButtonWithLoader
                                    variant="primary"
                                    onClick={handleDownloadPdf}
                                    loading={isDownloadingPdf}
                                >
                                    <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.BTN_DOWNLOAD_PRINT" />
                                </ButtonWithLoader>
                                <ButtonWithLoader variant="primary" onClick={handleSendEmail} loading={isSendingEmail}>
                                    <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.BTN_SEND_EMAIL" />
                                </ButtonWithLoader>
                            </div>
                        )}
                    </div>
                }
            />
        </FormProvider>
    );
};

export default APVInStore;
